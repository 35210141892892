// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/AnchorLink/AnchorLink.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/AnchorLink/AnchorLink.tsx");
  import.meta.hot.lastModified = "1734064315000";
}
// REMIX HMR END

import { NavLink } from '@remix-run/react';
import { cn } from '~/lib/utils';
export const AnchorLink = ({
  children,
  to,
  prefix = '',
  className = '',
  activeClassName = '',
  pendingClassName = '',
  prefetch = 'intent',
  ...props
}) => {
  return <NavLink to={`${prefix}${to}`} className={({
    isActive,
    isPending
  }) => cn(className, isPending ? pendingClassName : isActive ? activeClassName : '')} prefetch={prefetch} {...props}>
      {children}
    </NavLink>;
};
_c = AnchorLink;
var _c;
$RefreshReg$(_c, "AnchorLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;